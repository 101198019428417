import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import settings from "../../constants/settings"
import userService from "../../services/user-service"
import userBehaviourUtils from "../../utils/userBehaviour-utils"
import { updateFormField, updateFormFields } from "../forms/formsActions"
import { history } from "../store"
import { getTeamAccounts, getTeamsForUser } from "../team-management/teamManagementActions"
import { logoutUser } from "./authActions"

function loginUserSuccess(userData) {
  return { type: ACTIONS.USER_LOGIN, userData }
}

function getUserData(noRedirect = false) {
  return async dispatch => {
    try {
      const userData = await userService.getUserData()
      const account = userData.data.result.user
      await dispatch(loginUserSuccess(account))
      userBehaviourUtils.sessionStarted()
    } catch (error) {
      if (!noRedirect) {
        history.replace("/login")
      }
    }
  }
}

function getSeatSuccess(seat) {
  return {
    type: ACTIONS.GET_SEAT,
    seat,
  }
}

function addAccount(accountName, startUTCTime, endUTCTime, timezone) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      const { teamSearch } = getState().teamManagement

      const planId = 1
      const data = {
        planId,
        fullName: accountName,
        startUTCTime,
        endUTCTime,
        timeZone: timezone,
        teamId: selectedTeam.teamId,
        initialUserId: selectedTeam.initialUserId,
      }
      const account = await userService.addAccount(userID, data)
      await dispatch(getTeamsForUser(teamSearch))
      await dispatch(getTeamAccounts())
      dispatch(getSeatSuccess(account.data.result))
      return account.data.result
    } catch (error) {
      return false
    }
  }
}

function resendConfirmationEmail() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.resendConfirmationEmail(userID)
      toast.success("Confirmation email successfully sent!")
    } catch (error) {}
  }
}

function clearAllUsers() {
  return async dispatch => {
    try {
      dispatch({ type: ACTIONS.CLEAR_ALL_USERS })
    } catch (error) {}
  }
}

function getAllUsersSuccess(previousUsers, allNewUsers, offset) {
  return {
    type: ACTIONS.GET_ALL_USERS,
    allUsers: previousUsers.concat(allNewUsers),
    userCount: allNewUsers.length > 0 ? offset + settings.whitelabelAccountsLimit : 0,
  }
}

function getAllUsers(search = "", limit = settings.whitelabelAccountsLimit, offset = 0) {
  return async (dispatch, getState) => {
    try {
      const realSearch = search
        ? `?search=${search}&limit=${limit}&offset=${offset}`
        : `?limit=${limit}&offset=${offset}`
      const allUsers = await userService.getAllUsers(realSearch)

      dispatch(
        getAllUsersSuccess(
          getState().user.allUsers,
          allUsers.data.result.items,
          offset || settings.whitelabelAccountsLimit,
        ),
      )
    } catch (error) {}
  }
}

function getActiveUserSuccess(activeUser) {
  return { type: ACTIONS.GET_ACTIVE_USER, activeUser }
}

function getActiveUser() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const activeUser = await userService.getActiveUser(userID)
      dispatch(getActiveUserSuccess(activeUser.data))
    } catch (error) {}
  }
}

function deleteUser() {
  return async (dispatch, getState) => {
    try {
      const { id } = getState().user.profile
      history.replace("/login")
      await userService.deleteUser(id)
      dispatch(logoutUser())
      toast.success("Account successfully deleted")
    } catch (error) {}
  }
}

function changeUserName(userID) {
  return async (dispatch, getState) => {
    try {
      const newName = getState().forms.formData.userName
      const { allUsers, userCount } = getState().user
      await userService.changeUserName(userID, newName)
      dispatch({
        type: ACTIONS.GET_ALL_USERS,
        allUsers: allUsers.map(user => {
          if (user.id === userID) {
            user.fullName = newName
          }
          return user
        }),
        userCount,
      })
      toast.success("User name changed")
    } catch (error) {}
  }
}

function getUserProduct() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const productInfo = await userService.getUserProduct(userID)
      return productInfo.data
    } catch (error) {
      return false
    }
  }
}

function getAllSavedRepliesSuccess(savedReplies) {
  return {
    type: ACTIONS.GET_ALL_SAVED_REPLIES,
    savedReplies,
  }
}

function getAllSavedReplies() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const savedReplies = await userService.getAllSavedReplies(userID)

      dispatch(getAllSavedRepliesSuccess(savedReplies.data.result.items))
    } catch (error) {
      return false
    }
  }
}

function crateSavedReply(title, savedReply) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.crateSavedReply(userID, { title, savedReply })
      await dispatch(getAllSavedReplies())

      dispatch(updateFormField("savedReplyTitle", ""))
      dispatch(updateFormField("savedReplyText", ""))

      toast.success("Saved reply successfully created")
    } catch (error) {
      return false
    }
  }
}

function editSavedReply(title, savedReply, savedReplyId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.editSavedReply(userID, savedReplyId, { title, savedReply })
      await dispatch(getAllSavedReplies())

      dispatch(
        updateFormFields({
          savedReplyId: "",
          savedReplyTitle: "",
          savedReplyText: "",
        }),
      )

      toast.success("Saved reply successfully edited")
    } catch (error) {
      return false
    }
  }
}

function deleteSavedReply(savedReplyId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.deleteSavedReply(userID, savedReplyId)
      await dispatch(getAllSavedReplies())

      toast.success("Saved reply successfully deleted")
    } catch (error) {
      return false
    }
  }
}

function getAllFeatureRequestsSuccess(featureRequests, init) {
  return {
    type: ACTIONS.GET_ALL_FEATURE_REQUESTS,
    featureRequests,
    init,
  }
}

function getAllFeatureRequests(sortColumn, sortOrder, status, limit, offset, search, init = false) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      let query = `?sortOrder=${sortOrder}&sortColumn=${sortColumn}&limit=${limit}&offset=${offset}&search=${encodeURIComponent(
        search,
      )}`
      if (status !== 0) {
        query += `&status=${status}`
      }
      const featureRequests = await userService.getAllFeatureRequests(userID, query)

      dispatch(getAllFeatureRequestsSuccess(featureRequests.data.result, init))
    } catch (error) {
      return false
    }
  }
}

function getFeatureRequestByIDSuccess(featureRequest) {
  return {
    type: ACTIONS.GET_FEATURE_REQUESTS_BY_ID,
    featureRequest,
  }
}

function getFeatureRequestByID(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const featureRequests = await userService.getFeatureRequestByID(userID, featureID)

      dispatch(getFeatureRequestByIDSuccess(featureRequests.data))
    } catch (error) {
      return false
    }
  }
}

function createFeatureRequest(title, details) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.createFeatureRequest(userID, { title, details })
      dispatch(updateFormField("feature_title", ""))
      dispatch(updateFormField("feature_details", ""))
      toast.success("Feature request successfully created")
    } catch (error) {
      return false
    }
  }
}

function addFeatureRequestComment(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const comment = getState().forms.formData["feature-request-comment"]
      await userService.addFeatureRequestComment(userID, featureID, { comment })
      dispatch(updateFormField("feature-request-comment", ""))
      dispatch(getFeatureRequestByID(featureID))
    } catch (error) {
      return false
    }
  }
}

function voteForFeatureRequest(featureID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await userService.voteForFeatureRequest(userID, featureID)
    } catch (error) {
      return false
    }
  }
}

function getLinkedinUserSuccess(linkedinUserData) {
  return {
    type: ACTIONS.GET_LINKEDIN_USER,
    linkedinUserData,
  }
}

function clearLinkedinUser() {
  return {
    type: ACTIONS.CLEAR_LINKEDIN_USER,
  }
}

function getLinkedinUser(linkedinUserId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const linkedinUserData = await userService.getLinkedinUser(userID, accountID, linkedinUserId)
      dispatch(getLinkedinUserSuccess(linkedinUserData.data.result))
    } catch (error) {}
  }
}

function getOpenApiKeySuccess(openApiKey) {
  return {
    type: ACTIONS.GET_OPEN_API_KEY,
    openApiKey,
  }
}

function getOpenApiKey() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const apiKey = await userService.getOpenApiKey(userID)
      dispatch(getOpenApiKeySuccess(apiKey.data))
    } catch (error) {}
  }
}

export {
  getUserData,
  addAccount,
  clearAllUsers,
  getAllUsers,
  resendConfirmationEmail,
  getUserProduct,
  crateSavedReply,
  editSavedReply,
  deleteSavedReply,
  getAllSavedReplies,
  getAllFeatureRequests,
  getFeatureRequestByID,
  createFeatureRequest,
  addFeatureRequestComment,
  voteForFeatureRequest,
  deleteUser,
  changeUserName,
  getActiveUser,
  getLinkedinUser,
  clearLinkedinUser,
  getOpenApiKey,
}
