import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import userService from "../../services/user-service"
import config from "../../utils/config"
import userBehaviourUtils from "../../utils/userBehaviour-utils"
import { clearForm, clearFormErrors, setFormErrors } from "../forms/formsActions"
import { history } from "../store"

// function loginUserSuccess(userData) {
//   return { type: ACTIONS.USER_LOGIN, userData }
// }

function userLogin(email, password, recaptcha, hash) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))
      const iframe = localStorage.getItem("iframe") === "true"
      await userService.loginUser(email, password, recaptcha, hash, iframe)
      // await dispatch(loginUserSuccess(userData.data.result))
      userBehaviourUtils.userLogin({ email })
      dispatch(clearForm())
      dispatch(clearFormErrors())
      history.replace("/")
    } catch (error) {
      const errorMessages = []
      if (error?.response?.status === 401) {
        dispatch(setFormErrors({ msg: "Wrong credentials" }))
      } else if (error?.response && error.response?.data && error.response?.data?.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      } else {
        dispatch(setFormErrors({ msg: "Something went wrong" }))
      }
    }
  }
}

function registerUserSuccess(userData) {
  return { type: ACTIONS.USER_REGISTER, userData }
}

function registerUser(email, password, fullName, companyName, phone, hash) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))
      const invitationID = config.REACT_APP_SECURED_REGISTRATION
        ? localStorage.getItem("invitationID") || undefined
        : undefined

      const userData = await userService.registerUser(
        email,
        password,
        fullName,
        companyName,
        phone,
        invitationID,
        hash,
      )
      if (invitationID) {
        localStorage.removeItem("invitationID")
      }
      await dispatch(registerUserSuccess(userData.data.result))
      userBehaviourUtils.registrationCompleted(userData.data.result)
      dispatch(clearForm())
      dispatch(clearFormErrors())
      history.replace("/")
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          setFormErrors({
            msg: "Please choose a different email, this is already in use!",
          }),
        )
      }
      const errorMessages = []
      if (error.response && error.response.data && error.response.data.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      }
    }
  }
}

function logoutUserSuccess() {
  return { type: ACTIONS.USER_LOGOUT }
}

function logoutUser() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const selectedTheme = localStorage.getItem("theme")
      localStorage.clear()
      localStorage.setItem("theme", selectedTheme)
      if (history.location.pathname !== "/login") {
        history.replace("/login")
      }
      await userService.logoutUser(userID)
      dispatch(logoutUserSuccess())
      userBehaviourUtils.sessionEnded()
    } catch (err) {
      if (err.code === 401) {
        dispatch(logoutUserSuccess())
        localStorage.clear()
        if (history.location.pathname !== "/login") {
          history.replace("/login")
        }
        userBehaviourUtils.sessionEnded()
      }
    }
  }
}

function changePassword(data) {
  return async (_, getState) => {
    try {
      const userID = getState().user.profile.id

      await userService.changePassword(userID, data)
      toast.success("Password successfully changed!")
    } catch (error) {}
  }
}

function resetPassword(email) {
  return async () => {
    try {
      await userService.resetPassword(email)
      toast.success("Email successfully sent")
      return true
    } catch (error) {
      return false
    }
  }
}

function setPassword(password, passwordResetToken) {
  return async () => {
    try {
      await userService.setPassword({ password, passwordResetToken })
      toast.success("Password successfully changed. You can now login")
      return true
    } catch (error) {
      return false
    }
  }
}

export { userLogin, registerUser, logoutUser, changePassword, resetPassword, setPassword }
