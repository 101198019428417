import moment from "moment-timezone"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import { CHAT_TYPE_FILTER_DEFAULT_OPTIONS } from "../../constants/chat-constants"
import campaignService from "../../services/campaign-service"
import chatService from "../../services/chat-service"
import * as ChatUtils from "../../utils/chat-utils"
import dataUtils from "../../utils/data-utils"
import { getLeadLabels } from "../../utils/label-utils"
import { hasPermission, PERMISSIONS } from "../../utils/team-management-utils"
import { getEmailAccountSettings, resetEmailAccountSettings } from "../actions"
import { clearForm, updateFormField, updateFormFields } from "../forms/formsActions"
import { initialState } from "./chatReducer"

function getTagsForLeadsSuccess(leadsChatLabels) {
  return { type: ACTIONS.GET_LEADS_CHAT_LABELS, leadsChatLabels }
}

function getTagsForLeads(leadIds, oldTags, allConversationsCount) {
  return async (dispatch, getState) => {
    try {
      if (typeof allConversationsCount !== "undefined" && allConversationsCount === 0) {
        return
      }
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeadIds = leadIds.filter(leadId => !!leadId)
      const leadsChatLabels = await chatService.getTagsForLeads(userID, accountID, allLeadIds)
      await dispatch(
        getTagsForLeadsSuccess(
          dataUtils.filterUniqueObject([...leadsChatLabels.data.result.items, ...oldTags]),
        ),
      )
    } catch (error) {}
  }
}

function deselectChatSuccess() {
  return {
    type: ACTIONS.DESELECT_CHAT,
  }
}

function addCampaignIdToLead(campaignId) {
  return {
    type: ACTIONS.ADD_CAMPAIGN_ID_TO_LEAD,
    payload: campaignId,
  }
}

function deselectChat() {
  return dispatch => {
    try {
      dispatch(deselectChatSuccess())
    } catch (error) {}
  }
}

function getMessagesForLinkedinUserSuccess(linkedinUserMessages) {
  return {
    type: ACTIONS.GET_MESSAGES_FOR_LINKEDIN_USER,
    payload: linkedinUserMessages,
  }
}

function getMessagesForLinkedinUser(linkedinUserId) {
  return async (dispatch, getState) => {
    const userID = getState().user.profile.id
    const accountID = getState().account.activeAccountID
    const linkedinUserMessages = await chatService.getMessagesForLinkedinUser(
      userID,
      accountID,
      linkedinUserId,
    )

    dispatch(getMessagesForLinkedinUserSuccess(linkedinUserMessages.data.result.messages))
  }
}

function setLinkedinUserMessage(linkedinUserMessages) {
  return {
    type: ACTIONS.SET_LINKEDIN_USER_MESSAGE,
    linkedinUserMessages,
  }
}

function loadingMessages(loadingChatMessages = true) {
  return {
    type: ACTIONS.LOADING_MESSAGES,
    loadingChatMessages,
  }
}

function getBelongedLeadsForThreadSuccess(belongedLeads) {
  return {
    type: ACTIONS.GET_BELONGED_LEADS_FOR_THREAD,
    belongedLeads,
  }
}

function getBelongedLeadsForThread(thread) {
  return async (dispatch, getState) => {
    const userID = getState().user.profile.id
    const accountID = getState().account.activeAccountID
    const encodedThread = encodeURIComponent(thread)
    const belongedLeads = await chatService.getBelongedLeadsForThread(
      userID,
      accountID,
      encodedThread,
    )
    dispatch(getBelongedLeadsForThreadSuccess(belongedLeads.data.result.items))
  }
}

function getConversationsForCampaignSuccess(conversations, offset, campaignID) {
  return {
    type: ACTIONS.GET_CAMPAIGN_MESSAGES,
    conversations,
    conversationsCount: conversations.length > 0 ? offset + 30 : 0,
    campaignID,
  }
}

function updateCampaignID(campaignID) {
  return {
    type: ACTIONS.UPDATE_CAMPAIGN_ID,
    campaignID,
  }
}

function setConversations(conversations) {
  return {
    type: ACTIONS.SET_CONVERSATIONS,
    conversations: conversations.map(conversation => {
      conversation.checked = false
      return conversation
    }),
  }
}

function replaceConversations(conversations) {
  return {
    type: ACTIONS.SET_CONVERSATIONS,
    conversations,
  }
}

function getMessages(linkedinUserId) {
  return async (dispatch, getState) => {
    try {
      let allMessages = []
      await dispatch(getMessagesForLinkedinUser(linkedinUserId))
      const { linkedinUserMessages } = getState().chat
      allMessages = linkedinUserMessages

      return allMessages
    } catch (err) {}
  }
}

function getConversationsForCampaign(
  campaignID,
  filter = {
    search: "",
    tagsId: [],
    types: [],
    mailboxIds: [],
    filterConnectedNotReplied: false,
    filterUnassigned: false,
  },
  limit = 30,
  offset = 0,
  isCalledOnFirstRender = false,
) {
  return async (dispatch, getState) => {
    try {
      const shouldClear = offset === 0
      if (shouldClear) {
        await dispatch({ type: ACTIONS.CLEAR_FILTERED_ITEMS })
        dispatch(loadingMessages())
      }
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      let tagsParam = ""
      let typeParam = ""
      let mailboxIdsParam = ""
      let filterConnectedNotRepliedParam = ""
      let filterUnassignedParam = ""
      const { tagsId, types, mailboxIds, search, filterConnectedNotReplied, filterUnassigned } =
        filter
      if (tagsId.length) {
        if (tagsId.indexOf("[") !== -1 && tagsId.indexOf("]") !== -1) {
          tagsParam = `&tagIds=${tagsId}`
        } else {
          tagsParam = `&tagIds=[${tagsId}]`
        }
      }
      if (types.length) {
        typeParam = `&types=${JSON.stringify(types)}`
      }
      if (mailboxIds.length) {
        mailboxIdsParam = `&mailboxIds=${JSON.stringify(mailboxIds)}`
      }
      if (filterConnectedNotReplied) {
        filterConnectedNotRepliedParam = `&filterConnectedNotReplied=${filterConnectedNotReplied}`
      }
      if (filterUnassigned) {
        filterUnassignedParam = `&filterUnassigned=${filterUnassigned}`
      }
      const realSearch = search
        ? `?name=${encodeURIComponent(
            search,
          )}&limit=${limit}&offset=${offset}${filterConnectedNotRepliedParam}${tagsParam}${filterUnassignedParam}${typeParam}${mailboxIdsParam}`
        : `?limit=${limit}&offset=${offset}${filterConnectedNotRepliedParam}${tagsParam}${filterUnassignedParam}${typeParam}${mailboxIdsParam}`
      const conversations = await chatService.getConversationsForCampaign(
        userID,
        accountID,
        campaignID,
        realSearch,
      )
      const conversationForCampaign = conversations.data.result.items

      let leadsChatLabels = []
      if (offset > 0) {
        leadsChatLabels = getState().chat.leadsChatLabels
      }
      const linkedinUserIds = []
      const allLeadIds = []
      for (let i = 0; i < conversationForCampaign.length; i++) {
        const element = conversationForCampaign[i]
        linkedinUserIds.push(element.linkedinUser.id)
        allLeadIds.push(element.lead.id)
      }
      if (allLeadIds.length) {
        await dispatch(
          getTagsForLeads(allLeadIds, leadsChatLabels, conversationForCampaign.length || 0),
        )
      }

      const allConversations = dataUtils.mapConversations(conversationForCampaign)
      if (isCalledOnFirstRender) {
        dispatch(setConversations(allConversations))
      } else {
        await dispatch(
          getConversationsForCampaignSuccess(allConversations, offset || 30, campaignID),
        )
      }
      dispatch(loadingMessages(false))
    } catch (error) {
      dispatch(loadingMessages(false))
    }
  }
}

function selectChannelSuccess(channel) {
  return {
    type: ACTIONS.SELECT_CHANNEL,
    channel,
  }
}

function seenMessage(currentChannel) {
  return async (dispatch, getState) => {
    try {
      const { thread } = currentChannel
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { conversations, conversationsCount, selectedConversation } = getState().chat

      await chatService.seenMessage(
        userID,
        accountID,
        encodeURIComponent(thread),
        selectedConversation.linkedinUser.id,
      )

      let { linkedinUserMessages } = getState().chat
      const { allMessages } = selectedConversation
      const dashboardId = currentChannel.channel
      const seenThreads = {}

      for (const [linkedinUserMessageThread, messages] of Object.entries(
        linkedinUserMessages?.channels?.[dashboardId]?.threads,
      )) {
        const seenedMessages = messages.map(message => {
          if (linkedinUserMessageThread === thread) {
            return {
              ...message,
              seenAt: Date.now(),
            }
          }

          return message
        })

        seenThreads[linkedinUserMessageThread] = seenedMessages
      }

      linkedinUserMessages = {
        ...linkedinUserMessages,
        channels: {
          ...linkedinUserMessages?.channels,
          [dashboardId]: {
            ...linkedinUserMessages?.channels?.[dashboardId],
            threads: seenThreads,
          },
        },
      }

      dispatch(setLinkedinUserMessage(linkedinUserMessages))

      const allChannels = Object.keys(linkedinUserMessages.channels)
      let isMessageRead = true

      for (const channel of allChannels) {
        const threads = Object.values(linkedinUserMessages?.channels?.[channel]?.threads)

        for (const messages of threads) {
          for (const message of messages) {
            if (message.seenAt === null) {
              isMessageRead = false
            }
          }
        }
      }

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => {
          if (conversation.linkedinUser.id === selectedConversation.linkedinUser.id) {
            return {
              ...conversation,
              linkedinUser: {
                ...conversation.linkedinUser,
                messageSeenAt: Date.now(),
                isMessageRead,
              },
              lastChannelThread: {
                ...conversation.lastChannelThread,
                seenAt: Date.now(),
              },
            }
          }

          return conversation
        }),
        conversationsCount,
      })

      dispatch({
        type: ACTIONS.REPLACE_CHANNEL,
        selectedConversation: {
          ...selectedConversation,
          allMessages: {
            ...allMessages,
            channels: allMessages.channels.map(channel =>
              channel.thread === thread ? { ...channel, seenAt: Date.now() } : channel,
            ),
          },
        },
      })
    } catch (error) {}
  }
}

function addReturnedTimeFromCampaign(messagesArray, returnedTimeToCampaign) {
  let foundReturnedTime = false
  let index = messagesArray.length

  if (returnedTimeToCampaign.createdAt === 0) {
    return messagesArray
  }

  for (let i = 0; i < messagesArray.length; i++) {
    if (messagesArray[i].createdAt === returnedTimeToCampaign.createdAt) {
      foundReturnedTime = true
      break
    }
    if (messagesArray[i].createdAt > returnedTimeToCampaign.createdAt) {
      index = i
      break
    }
  }

  if (!foundReturnedTime) {
    messagesArray.splice(index, 0, returnedTimeToCampaign)
    return messagesArray
  }

  return messagesArray
}

const setMessagesSuccess = (messages, keepChannels = false) => ({
  type: ACTIONS.SET_MESSAGES,
  payload: { messages, keepChannels },
})

const addNewMessageSuccess = newMessage => ({
  type: ACTIONS.ADD_NEW_MESSAGE,
  newMessage,
})

const deleteMessage = (messageID, thread) => ({
  type: ACTIONS.DELETE_MESSAGE,
  payload: { messageID, thread },
})

function setCanReplyToEmailThread(canReply) {
  return {
    type: ACTIONS.SET_CAN_REPLY_TO_EMAIL_THREAD,
    payload: canReply,
  }
}

function getCanReplyToEmailThread(thread, query) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const canReply = await chatService.getCanReplyToEmailThread(userID, accountID, thread, query)
      dispatch(setCanReplyToEmailThread(canReply.data.result.status))
    } catch (error) {}
  }
}

function handleCanReplyToEmailThread(thread, leadId) {
  return async dispatch => {
    if (thread && leadId) {
      await dispatch(getCanReplyToEmailThread(encodeURIComponent(thread), `?leadId=${leadId}`))
    }
  }
}

function selectChannel(channel, setMessages) {
  return async (dispatch, getState) => {
    try {
      const { linkedinUserMessages, selectedConversation } = getState().chat
      if (hasPermission(PERMISSIONS.CHAT_EDIT)) {
        await dispatch(seenMessage(channel))
      }
      dispatch(selectChannelSuccess(channel))

      const leadId = selectedConversation?.lead?.id

      await dispatch(handleCanReplyToEmailThread(channel.thread, leadId))
      const dashboardId = channel?.channel

      const { channelMessages, lastMessage } = ChatUtils.getChannelMessagesAndLastMessage(
        linkedinUserMessages,
        dashboardId,
        channel?.thread,
      )

      if (
        lastMessage.mailboxId &&
        dashboardId === 4 &&
        !(lastMessage.mailboxStatus === "INACTIVE" && lastMessage.mailboxDeletedAt !== null)
      ) {
        await dispatch(getEmailAccountSettings(lastMessage.mailboxId))
      } else {
        dispatch(resetEmailAccountSettings())
      }

      if (setMessages) {
        dispatch(setMessagesSuccess(channelMessages, true))
      }
    } catch (error) {}
  }
}

const setChannelsSuccess = channels => ({
  type: ACTIONS.SET_CHANNELS,
  channels,
})

function getLeadInfoSuccess(leadInfo) {
  return {
    type: ACTIONS.GET_LEAD_INFO,
    leadInfo,
  }
}

function getLeadInfo(leadID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const belongedLeads = await chatService.getLeadInfo(userID, accountID, leadID)
      dispatch(getLeadInfoSuccess(belongedLeads.data.result))
    } catch (error) {}
  }
}

function selectChatSuccess(selectedConversation) {
  return {
    type: ACTIONS.SELECT_CHAT,
    selectedConversation,
  }
}

function selectChat(selectedConversation) {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingMessages())
      dispatch(
        updateFormFields({
          loadingChat: true,
          loadingLinkedInUser: true,
        }),
      )

      if (!selectedConversation?.linkedinUser?.id) {
        const { conversations } = getState().chat
        if (conversations[0]) {
          // eslint-disable-next-line prefer-destructuring
          selectedConversation = conversations[0]
        } else {
          selectedConversation = initialState.selectedConversation
          dispatch({ type: ACTIONS.CLEAR_FILTERED_ITEMS })
          dispatch({ type: ACTIONS.CLEAR_LINKEDIN_USER })
          dispatch(deselectChat())
        }
      }

      const selectedConversationLinkedinUserId = selectedConversation.linkedinUser.id

      if (selectedConversationLinkedinUserId) {
        await dispatch(getMessagesForLinkedinUser(selectedConversationLinkedinUserId))
      }

      const { linkedinUserMessages } = getState().chat

      const selectedConversationDashboardId = selectedConversation.linkedinUser.messageDashboard
      const selectedConversationLastMessage = selectedConversation.linkedinUser.lastMessage

      const selectorData = linkedinUserMessages

      /** TODO MJ@Any: Used to find thread with lastMessage. Check if this is the better way to do this because this is looping over all threads */
      let threadWithLastMessage = ""

      Object.values(linkedinUserMessages.channels[selectedConversationDashboardId].threads).forEach(
        thread => {
          thread.forEach(conversation => {
            if (conversation.message === selectedConversationLastMessage) {
              threadWithLastMessage = conversation.thread
            }
          })
        },
      )

      const { mailboxId, campaignId } =
        linkedinUserMessages.channels[selectedConversationDashboardId].threads[
          threadWithLastMessage
        ][0]

      dispatch(selectChatSuccess(selectedConversation))

      await dispatch(
        selectChannel({
          channel: selectedConversationDashboardId,
          thread: threadWithLastMessage,
          mailboxId,
        }),
      )

      dispatch(
        setMessagesSuccess(
          linkedinUserMessages.channels[selectedConversationDashboardId].threads[
            threadWithLastMessage
          ],
        ),
      )

      if (
        selectedConversation.lead.id &&
        (hasPermission(PERMISSIONS.CAMPAIGN_DETAIL_VIEW) || hasPermission(PERMISSIONS.CHAT_VIEW))
      ) {
        await dispatch(getLeadInfo(selectedConversation.lead.id))
        /** TODO MJ@Any: Backend should add campaignId when we call getLeadInfo function! We can then remove this addCampaignIdToLead */
        dispatch(addCampaignIdToLead(campaignId))

        dispatch(updateFormField("note", getState().chat.selectedConversation.lead.note))
      }

      const { campaignBack } = getState().chat.selectedConversation.lead
      const returnedToCampaign = !!campaignBack

      if (returnedToCampaign) {
        const returnedTimeToCampaign = {
          returnedToCampaign,
          createdAt: campaignBack,
          seenAt: Date.now(),
        }
        dispatch(
          setMessagesSuccess(
            addReturnedTimeFromCampaign(
              linkedinUserMessages.channels[selectedConversationDashboardId].threads[
                threadWithLastMessage
              ],
              returnedTimeToCampaign,
            ),
          ),
        )
      }

      if (selectorData.channels && selectorData.channels[selectedConversationDashboardId]) {
        const allChannels = Object.keys(selectorData.channels)
        const allThreadChannels = []

        for (let i = 0; i < allChannels.length; i++) {
          const currentChannel = allChannels[i]
          const channelThreads = Object.keys(selectorData.channels[currentChannel].threads)

          for (let j = 0; j < channelThreads.length; j++) {
            const currentThread = channelThreads[j]
            const lastMessageInThreadIndex =
              selectorData.channels[currentChannel].threads[currentThread].length - 1

            allThreadChannels.push({
              channel: +currentChannel,
              thread: currentThread,
              createdAt: selectorData.channels[currentChannel].threads[currentThread][0]?.createdAt,
              seenAt:
                currentThread === threadWithLastMessage
                  ? Date.now()
                  : selectorData.channels[currentChannel].threads[currentThread][
                      lastMessageInThreadIndex
                    ]?.seenAt,
              mailboxId: selectorData.channels[currentChannel].threads[currentThread][0]?.mailboxId,
            })
          }
        }

        dispatch(setChannelsSuccess(allThreadChannels))
      }

      if (selectedConversation.thread) {
        await dispatch(getBelongedLeadsForThread(selectedConversation.thread))
      }
    } catch (error) {
      dispatch(loadingMessages(false))
    } finally {
      dispatch(
        updateFormFields({
          loadingChat: false,
          loadingLinkedInUser: false,
        }),
      )
    }
  }
}

function sendMessages(thread, data, file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const response = await chatService.sendMessages(
        userID,
        accountID,
        encodeURIComponent(thread),
        data,
        file,
      )

      dispatch(addNewMessageSuccess(response.data))
      dispatch(getMessagesForLinkedinUserSuccess(dataUtils.addNewMessages(response.data)))

      return response.data
    } catch (error) {}
  }
}

function fetchLinkedinMessages() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      await chatService.fetchLinkedinMessages(userID, accountID)
      toast.success("You have queued a LinkedIn message refresh, it will be available in shortly")
    } catch (error) {}
  }
}

function fetchEmails() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      await chatService.fetchEmails(userID, accountID)
      toast.success("You have queued a Emails refresh, it will be available in shortly")
    } catch (error) {}
  }
}

function saveLeadNote(leadID, note) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await chatService.saveLeadNote(userID, accountID, leadID, note)
      const { conversations, conversationsCount } = getState().chat

      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => ({
          ...conversation,
          lead: {
            ...conversation.lead,
            note: conversation.lead.id === leadID ? note : conversation.lead.note,
          },
        })),
        conversationsCount,
      })

      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function getAllTagsSuccess(allChatLabels) {
  return { type: ACTIONS.GET_ALL_CHAT_LABELS, allChatLabels }
}

function getAllTags() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allChatLabels = await chatService.getAllTags(userID, accountID)
      dispatch(getAllTagsSuccess(allChatLabels.data.result.items))
    } catch (error) {}
  }
}

function createTag(tagName, color) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const tagData = await chatService.createTag(userID, accountID, tagName, color)
      await dispatch(getAllTags())
      return tagData.data.result
    } catch (error) {}
  }
}

function replaceSelectedConversationLabels(labels) {
  return { type: ACTIONS.REPLACE_SELECTED_CONVERSATION_LABELS, payload: { labels } }
}

function updateTagOrder(tagId, tagOrder) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      await chatService.updateTagOrder(userID, accountID, tagId, tagOrder)
    } catch (err) {}
  }
}

function editTag(tagId, tagName, color) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const labels = getState().chat.selectedConversation.labels || []
      const conversations = getState().chat.conversations || []

      const response = await chatService.editTag(userID, accountID, tagId, tagName, color)
      const updatedLabel = response.data.result || {}

      const newLabels = labels.map(label => (label.id === updatedLabel.id ? updatedLabel : label))

      const updatedConversations = conversations.map(conversation => {
        const indexOfUpdatedLabel = conversation.labels.findIndex(
          label => label.id === updatedLabel.id,
        )

        const updatedLabels = conversation.labels
        if (indexOfUpdatedLabel !== -1) {
          updatedLabels[indexOfUpdatedLabel] = updatedLabel
        }

        return {
          ...conversation,
          labels: updatedLabels,
        }
      })

      await dispatch(getAllTags())
      dispatch(replaceSelectedConversationLabels(newLabels))
      dispatch(replaceConversations(updatedConversations))
    } catch (error) {}
  }
}

function deleteTagSuccess(labelId) {
  return { type: ACTIONS.CLEAR_DELETED_TAG, labelId }
}

function deleteTag(tagId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await chatService.deleteTag(userID, accountID, tagId)
      dispatch(deleteTagSuccess(tagId))
      toast.success("Label successfully deleted")
      await dispatch(getAllTags())
    } catch (error) {}
  }
}

function refreshLabelToLeads(leadId) {
  return async (dispatch, getState) => {
    try {
      const { leadsChatLabels } = getState().chat
      await dispatch(
        getTagsForLeads(
          [leadId],
          leadsChatLabels.filter(chatLabel => leadId !== chatLabel.leadId),
        ),
      )

      const { conversations, conversationsCount, selectedConversation } = getState().chat

      const allConversations = dataUtils.mapConversations(conversations)

      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: allConversations,
        conversationsCount,
      })

      const newSelectedConversations = { ...selectedConversation }
      newSelectedConversations.labels = []
      if (newSelectedConversations.lead.id) {
        newSelectedConversations.labels = getLeadLabels(newSelectedConversations.lead.id)
      }
    } catch (error) {}
  }
}

function addLabelToLeadSuccess(label) {
  return { type: ACTIONS.ADD_LABEL_TO_LEAD, label }
}

function addLabelToLead(leadId, label) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      await chatService.addLabelToLead(userID, accountID, leadId, label.id)

      dispatch(addLabelToLeadSuccess(label))
      await dispatch(refreshLabelToLeads(leadId))
    } catch (error) {}
  }
}

function removeLabelFromLeadSuccess(labelId) {
  return { type: ACTIONS.REMOVE_LABEL_FROM_LEAD, labelId }
}

function removeLabelFromLead(leadId, labelId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await chatService.removeLabelFromLead(userID, accountID, leadId, labelId)

      await dispatch(removeLabelFromLeadSuccess(labelId))
      await dispatch(refreshLabelToLeads(leadId))
    } catch (error) {}
  }
}

function reloadChatState() {
  return { type: ACTIONS.RELOAD_CHAT_STATE }
}

function getLastFetchedConversationSuccess(lastFetchedTimestamp) {
  return { type: ACTIONS.GET_LAST_FETCHED_CONVERSATION_TIMESTAMP, lastFetchedTimestamp }
}

function getLastFetchedConversation() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const lastFetchedTimestamp = await chatService.getLastFetchedConversation(userID, accountID)
      dispatch(
        getLastFetchedConversationSuccess(lastFetchedTimestamp.data.result.lastGotMessagesUpdate),
      )
    } catch (error) {}
  }
}

function deleteChatMessage(messageID, selectedConversation) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { thread, linkedinUser } = selectedConversation
      const selectedConversationDashboardId = linkedinUser.messageDashboard

      await chatService.deleteChatMessage(
        userID,
        accountID,
        encodeURIComponent(thread),
        linkedinUser.id,
        messageID,
      )

      dispatch(deleteMessage(messageID, thread))

      const { linkedinUserMessages } = getState().chat

      const linkedinUserMessagesArray = linkedinUserMessages?.channels[
        selectedConversationDashboardId
      ].threads[thread].filter(mess => +mess.id !== +messageID)

      linkedinUserMessages.channels[selectedConversationDashboardId].threads[thread] =
        linkedinUserMessagesArray

      dispatch({ type: ACTIONS.REMOVE_LINKEDIN_USER_MESSAGE, linkedinUserMessages })

      const { conversations } = getState().chat
      dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => {
          if (
            conversation.linkedinUser.id === selectedConversation.linkedinUser.id &&
            conversation.thread === thread
          ) {
            return {
              ...conversation,
              linkedinUser: {
                ...conversation.linkedinUser,
                lastMessage:
                  linkedinUserMessagesArray[linkedinUserMessagesArray.length - 1].message,
              },
            }
          }

          return conversation
        }),
        conversationsCount: conversations.length,
      })

      if (hasPermission(PERMISSIONS.CHAT_VIEW)) {
        dispatch(getLastFetchedConversation())
      }
      // TODO(sb): Change conversations
      toast.success("Message successfully deleted")
    } catch (error) {}
  }
}

function markMessagesAsUnread(linkedinUserIds, thread) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const { conversationsCount, conversations, selectedConversation, selectedChannel } =
        getState().chat
      let { linkedinUserMessages } = getState().chat
      const { allMessages } = selectedConversation

      if (thread && linkedinUserIds[0]) {
        await chatService.markMessageAsUnread(
          userID,
          accountID,
          encodeURIComponent(thread),
          linkedinUserIds[0],
        )
      } else if (linkedinUserIds) {
        await chatService.markMessageAsUnreadByLinkedinUserIds(userID, accountID, linkedinUserIds)
      }

      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: conversations.map(conversation => {
          const selectedConversationForMapping = linkedinUserIds.includes(
            conversation.linkedinUser.id,
          )
          if (selectedConversationForMapping) {
            return {
              ...conversation,
              linkedinUser: {
                ...conversation.linkedinUser,
                messageSeenAt:
                  thread && linkedinUserIds[0] ? conversation.linkedinUser.messageSeenAt : null,
                isMessageRead: false,
              },
              lastChannelThread: {
                ...conversation.lastChannelThread,
                seenAt:
                  thread && linkedinUserIds[0] ? conversation.linkedinUser.messageSeenAt : null,
              },
            }
          }

          return conversation
        }),
        conversationsCount,
      })

      dispatch({
        type: ACTIONS.REPLACE_CHANNEL,
        selectedConversation: {
          ...selectedConversation,
          allMessages: {
            ...allMessages,
            channels:
              thread && linkedinUserIds[0]
                ? allMessages.channels.map(channel =>
                    selectedChannel.thread.includes(channel.thread)
                      ? { ...channel, seenAt: null }
                      : channel,
                  )
                : allMessages.channels.map(channel =>
                    linkedinUserIds.includes(selectedConversation?.linkedinUser?.id)
                      ? { ...channel, seenAt: null }
                      : channel,
                  ),
          },
        },
      })

      const dashboardId = selectedChannel.channel
      const unreadThreads = {}

      for (const [linkedinUserMessageThread, messages] of Object.entries(
        linkedinUserMessages?.channels?.[dashboardId]?.threads,
      )) {
        const unreadMessages = messages.map(message => {
          if (thread && linkedinUserIds[0]) {
            if (linkedinUserMessageThread.includes(selectedChannel.thread)) {
              return {
                ...message,
                seenAt: null,
              }
            }

            return message
          }

          if (linkedinUserIds.includes(selectedConversation?.linkedinUser?.id)) {
            return {
              ...message,
              seenAt: null,
            }
          }

          return message
        })

        unreadThreads[linkedinUserMessageThread] = unreadMessages
      }

      linkedinUserMessages = {
        ...linkedinUserMessages,
        channels: {
          ...linkedinUserMessages?.channels,
          [dashboardId]: {
            ...linkedinUserMessages?.channels?.[dashboardId],
            threads: unreadThreads,
          },
        },
      }
      dispatch(setLinkedinUserMessage(linkedinUserMessages))

      toast.success("Message successfully marked as unread")
    } catch (error) {}
  }
}

function archiveConversations(archived, linkedinUserIds) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const data = { archived, linkedinUserIds }

      await chatService.archiveMessages(userID, accountID, data)

      const { conversationsCount, conversations } = getState().chat

      const filteredConversations = conversations.filter(
        conversation => !linkedinUserIds.includes(conversation?.linkedinUser?.id),
      )

      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: filteredConversations,
        conversationsCount: conversationsCount - linkedinUserIds.length,
      })

      toast.success(`Messages successfully ${archived ? "archived" : "restored"}`)
    } catch (error) {}
  }
}

function checkAllConversations(checked) {
  return async (dispatch, getState) => {
    const { conversationsCount, conversations } = getState().chat
    await dispatch({
      type: ACTIONS.SET_CONVERSATIONS,
      conversations: conversations.map(conversation => {
        conversation.checked = checked
        return conversation
      }),
      conversationsCount,
    })
  }
}

function checkConversation(linkedinUserId, checked) {
  return async (dispatch, getState) => {
    const { conversationsCount, conversations } = getState().chat
    await dispatch({
      type: ACTIONS.SET_CONVERSATIONS,
      conversations: conversations.map(conversation => {
        if (conversation?.linkedinUser?.id === linkedinUserId) {
          conversation.checked = checked
        }
        return conversation
      }),
      conversationsCount,
    })
  }
}

function getLinkedinUserMessagesSuccess(linkedinUserMessages) {
  return {
    type: ACTIONS.GET_LINKEDIN_USER_MESSAGES,
    linkedinUserMessages,
  }
}

function addLeadBackToCampaignForChat(leadID, nextStepId, campaignId) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms
      const newStartUTCTime = moment.tz(
        `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
        "DD MM YYY HH:mm",
        moment.tz.guess(),
      )

      // We have to create a deep copy because of this dispatch(clearForm())
      const currentPage = +JSON.stringify(formData.currentPage)
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const doTaskAt = newStartUTCTime.valueOf()
      const leadsFromChat = getState().chat.conversations
      const { belongedLeads, lead } = getState().chat.selectedConversation
      const selectedCampaign = getState().chat.campaignID
      const { linkedinUserMessages, selectedConversation } = getState().chat

      const { allCampaignNames } = getState().campaign
      dispatch(clearForm())

      await campaignService.addLeadBackToCampaign(userID, accountID, leadID, {
        campaignId,
        step: nextStepId,
        doTaskAt,
      })
      const newCampaignName = allCampaignNames.find(campaign => campaign.id === campaignId).name

      const currentCampaignId = lead.campaignId

      const newBelongedLeads = ChatUtils.getNewBelongedLeadsWhenLeadGotBackToCampaign(
        belongedLeads,
        currentCampaignId,
        campaignId,
        nextStepId,
        doTaskAt,
        newCampaignName,
      )

      const newLeadsForChat = ChatUtils.mapNewLeadsForChatWhenLeadGotBackToCampaign(
        leadsFromChat,
        leadID,
        campaignId,
        doTaskAt,
        nextStepId,
      )

      const { returnedToStepInSameCampaign, filteredLeads: newFilteredLeadsForChat } =
        ChatUtils.checkIfLeadReturnedToTheStepInSameCampaign(
          selectedCampaign,
          newLeadsForChat,
          leadID,
          currentCampaignId,
        )

      dispatch(updateFormField("currentPage", currentPage))
      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: newFilteredLeadsForChat,
      })

      if (
        !returnedToStepInSameCampaign &&
        ![
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ALL.value,
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ARCHIVED.value,
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.CONNECTED_NOT_REPLIED.value,
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.OTHER.value,
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREAD.value,
          CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREPLIED.value,
        ].includes(selectedCampaign)
      ) {
        dispatch(deselectChat())
      } else {
        const selectedConversationAfterLeadReturned =
          newFilteredLeadsForChat.find(
            newLeadForChat =>
              newLeadForChat.campaignId === campaignId && newLeadForChat.lead.id === leadID,
          ) || selectedConversation

        selectedConversationAfterLeadReturned.belongedLeads = newBelongedLeads
        await dispatch(selectChat(selectedConversationAfterLeadReturned))

        const { selectedChannel } = getState().chat
        const newLinkedinUserMessages = { ...linkedinUserMessages }
        const newLinkedinUserMessage =
          newLinkedinUserMessages.channels[
            selectedConversationAfterLeadReturned.linkedinUser.messageDashboard
          ].threads[selectedChannel.thread]
        ChatUtils.checkForReturnToCampaign(newLinkedinUserMessage, doTaskAt)
        await dispatch(getLinkedinUserMessagesSuccess(newLinkedinUserMessages))
      }

      toast.success("Lead successfully returned into the campaign")
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteChatConversations(data) {
  return async (dispatch, getState) => {
    try {
      const { conversationsCount, conversations } = getState().chat

      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await chatService.deleteChatConversations(userID, accountID, data)

      const filteredConversations = conversations.filter(
        conversation => !data.linkedinUserIds.includes(conversation?.linkedinUser?.id),
      )

      await dispatch({
        type: ACTIONS.REPLACE_CAMPAIGN_MESSAGES,
        conversations: filteredConversations,
        conversationsCount: conversationsCount - data.linkedinUserIds.length,
      })

      toast.success("Conversation successfully deleted")
    } catch (error) {}
  }
}

function clearCanReplyToEmailThread() {
  return {
    type: ACTIONS.CLEAR_CAN_REPLY_TO_EMAIL_THREAD,
  }
}

export {
  archiveConversations,
  checkAllConversations,
  checkConversation,
  getConversationsForCampaign,
  getBelongedLeadsForThread,
  selectChannel,
  selectChat,
  sendMessages,
  seenMessage,
  fetchLinkedinMessages,
  fetchEmails,
  saveLeadNote,
  getAllTags,
  getTagsForLeads,
  createTag,
  editTag,
  deleteTag,
  addLabelToLead,
  removeLabelFromLead,
  reloadChatState,
  getLastFetchedConversation,
  markMessagesAsUnread,
  deleteChatMessage,
  addLeadBackToCampaignForChat,
  deselectChat,
  deleteChatConversations,
  updateCampaignID,
  getMessages,
  setConversations,
  setLinkedinUserMessage,
  clearCanReplyToEmailThread,
  getCanReplyToEmailThread,
  updateTagOrder,
}
