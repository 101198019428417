import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, useHistory, useLocation } from "react-router"
import { withRouter } from "react-router-dom"

import {
  clearTeamsForUser,
  getAccountData,
  getPermissions,
  getTeamForUser,
  getTeamsForUser,
  getUserData,
  getWhitelabelPermissions,
  setActiveAccountID,
  setSelectedTeam,
} from "../../../redux/actions"
import { store } from "../../../redux/store"
import { hasPermission } from "../../../utils/team-management-utils"
import { hasWhitelabelPermission, isNotSkylead } from "../../../utils/whitelabel-utils"
import LoadingPage from "../../pages/LoadingPage"

const AuthUserRoute = ({
  whitelabelPermission,
  permission,
  globalPermission,
  computedMatch,
  component,
  exact,
  path,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [initialLoaded, setInitialLoaded] = useState(false)

  const { user, activeAccountReduxID, userReduxID, userTeams, selectedTeamRedux } = useSelector(
    state => ({
      user: state.user.profile,
      activeAccountReduxID: state.account.activeAccountID,
      userReduxID: state.user.profile.id,
      userTeams: state.teamManagement.userTeams.items,
      selectedTeamRedux: state.teamManagement.selectedTeam,
    }),
  )

  const buildPermissionDeniedLink = () => {
    let permissionDeniedLink = location.pathname
    if (permissionDeniedLink[permissionDeniedLink.length - 1] !== "/") {
      permissionDeniedLink = `${permissionDeniedLink}/`
    }
    if (!permissionDeniedLink.includes("permission-denied")) {
      permissionDeniedLink = `${permissionDeniedLink}permission-denied`
    }
    return permissionDeniedLink
  }

  useEffect(() => {
    const initializeData = async callback => {
      const { userID, accountID, teamID } = computedMatch.params
      dispatch(setActiveAccountID(+accountID))

      if (
        !loading &&
        (callback ||
          (userID &&
            accountID &&
            activeAccountReduxID &&
            (userID.toString() !== userReduxID.toString() ||
              activeAccountReduxID.toString() !== accountID.toString())))
      ) {
        setLoading(true)

        await dispatch(getUserData())
        let currentUserID = userID
        if (!currentUserID) {
          currentUserID = store.getState().user.profile.id
        }

        /** This is used to get if account is suspended this could be improved but this the easiest for now */
        if (userID && accountID) {
          const accountData = await dispatch(getAccountData())
          if (accountData?.suspended === true) {
            history.replace("/404")
          }
        }

        if (!isNotSkylead()) {
          if (window.userGuiding) {
            window.userGuiding.identify(
              typeof store.getState().user?.profile?.id === "number"
                ? store.getState().user?.profile?.id.toString()
                : null,
              {
                email: store.getState().user?.profile?.email,
                account_id: accountID,
                created_at: moment().format(),
              },
            )
          }
        }

        dispatch(clearTeamsForUser())
        await dispatch(getTeamsForUser())

        const teams =
          userTeams && userTeams.length > 0
            ? userTeams
            : store.getState().teamManagement.userTeams.items

        let selectedTeam
        if (teamID) {
          selectedTeam =
            teams &&
            teams.length &&
            teams.find(userTeam => userTeam.teamId.toString() === teamID.toString())
        } else if (teams && currentUserID) {
          const team = teams[0]
          const foundTeam = teams.find(userTeam => userTeam.isTeamOwner)
          selectedTeam = foundTeam || team
        }

        if (selectedTeam) {
          dispatch(setSelectedTeam(selectedTeam))
        } else if (teamID) {
          await dispatch(getTeamForUser(teamID))
        }

        await dispatch(getPermissions())
        await dispatch(getWhitelabelPermissions())
        if (whitelabelPermission !== null && !hasWhitelabelPermission(whitelabelPermission)) {
          history.replace(buildPermissionDeniedLink())
        }

        const userTeam = selectedTeamRedux || store.getState().teamManagement.selectedTeam
        const doesNotHavePermission =
          userTeam &&
          !!(activeAccountReduxID || accountID) &&
          !!permission &&
          !hasPermission(permission)
        const doesNotHaveGlobalPermission =
          !!globalPermission &&
          !(user[globalPermission] || store.getState().user.profile[globalPermission])
        if (doesNotHavePermission && doesNotHaveGlobalPermission) {
          history.replace(buildPermissionDeniedLink())
        }

        if (callback) {
          await callback()
        }

        setLoading(false)
      }
    }

    if (!initialLoaded) {
      const initializeCallback = async () => {
        const userID = +computedMatch.params.userID
        const teams =
          userTeams && userTeams.length > 0
            ? userTeams
            : store.getState().teamManagement.userTeams.items
        if (userID && teams.length === 0) {
          history.replace("/unclaimed")
        }
        if (location.pathname === "/" && teams.length) {
          history.replace(`/users/${userID || store.getState().user?.profile?.id}/`)
        }

        setInitialLoaded(true)
      }
      initializeData(initializeCallback)
    } else {
      initializeData()
    }
  }, [])

  useEffect(() => {
    const { teamID, accountID } = computedMatch.params
    if (!teamID) {
      const { selectedTeam, userTeams: userTeamsRedux } = store.getState().teamManagement
      if (!selectedTeam) {
        const teams = userTeams && userTeams.length > 0 ? userTeams : userTeamsRedux.items
        dispatch(setSelectedTeam(teams[0]))
      }
    }
    if (accountID) {
      dispatch(setActiveAccountID(+accountID))
    }
  }, [location])

  return loading ? <LoadingPage /> : <Route exact={exact} path={path} component={component} />
}

AuthUserRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Object)]).isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  computedMatch: PropTypes.instanceOf(Object).isRequired,
  whitelabelPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  globalPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
}

AuthUserRoute.defaultProps = {
  exact: false,
  whitelabelPermission: null,
  permission: null,
  globalPermission: null,
}

export default withRouter(AuthUserRoute)
