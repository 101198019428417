export const HELP_LINKS = {
  ADD_NEW_USER_SEAT_ACCOUNT: {
    multilead: undefined,
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4409379826321-How-to-Add-a-New-User-Seat-and-Account/",
  },
  SET_UP_BILLING_INFORMATION_AND_MANAGE_YOUR_SUBSCRIPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550914577-How-to-Cancel-or-Delete-the-account/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4413757046033-How-to-Cancel-or-Delete-the-account/",
  },
  USE_PROXIES: {
    multilead: undefined,
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408477731217-How-to-Use-Proxies/",
  },
  EXPLANATION_OPTIONS_CAMPAIGN_TRACKING_DASH: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550602257-Explanation-of-Options-From-the-Campaigns-Dashboard/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408022098577-Explanation-of-Options-From-the-Campaigns-Dashboard/",
  },
  EXPORT_DATA_FROM_YOUR_SKYLEAD_ACCOUNT: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412712145553-How-to-export-data-from-your-account/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408432201617-How-to-export-data-from-Skylead-account/",
  },
  CAMPAIGN_SETTINGS_OPTIONS_FROM_LEAD_LIST: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412533515537-The-three-dots-menu-on-Campaigns-Dashboard/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408381771025-The-three-dots-menu-on-Campaigns-Dashboard/",
  },
  CREATE_SALES_NAVIGATOR_BASED_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550857361-Sales-Navigator-Search-Based-campaign/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408063679761-Sales-Navigator-Search-Based-campaign/",
  },
  CREATE_POST_ENGAGEMENT_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412536807441-Post-engagement-campaign/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408592736529-Post-engagement-campaign/",
  },
  CREATE_RECRUITER_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/knowledge/articles/5228918574353/en-us?brand_id=360004550457",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/5228918574353-How-to-connect-your-Recruiter-account",
  },
  CREATE_CSV_IMPORTED_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412762425105-CSV-imported-campaign/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408021913105-CSV-imported-campaign/",
  },
  MAKE_LINKEDIN_SEARCH_BASED_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412607642129-LinkedIn-Search-Based-Campaign/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408426918929-LinkedIn-Search-Campaign/",
  },
  CREATE_LEADS_LIST_ADN_USE_IT_FOR_OUTREACH_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412762152337-Leads-list-type-of-campaign/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408594700049-Leads-list-type-of-campaign/",
  },
  CONVERT_SAVED_SEARCH_URL_TO_REGULAR_URL: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412623656465-How-to-convert-Saved-Search-to-a-Regular-Search/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408022103313-How-to-convert-Saved-Search-to-a-Regular-Search/",
  },
  CREATE_SIMPLE_SEQUENCE: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412619038097-How-to-make-a-Smart-Sequence/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408029709841-How-to-create-a-Simple-Sequence",
  },
  USE_CUSTOM_VARIABLES: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/7568131129361-Custom-variables-",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408029560977-Custom-variables/",
  },
  MAKE_SMART_SEQUENCE: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412619038097-How-to-make-a-Smart-Sequence/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408426211345-How-to-make-a-Smart-Sequence/",
  },
  SMART_SEQUENCE_CUSTOM_CONDITION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550760209-Smart-Sequences-Custom-Condition/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408063976593-Smart-Sequences-Custom-Condition/",
  },
  USE_CAMPAIGN_SETTINGS_SECTION_TO_MAXIMIZE_YOUR_OUTREACH: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412533329809-Campaigns-settings/",
    skylead: "https://help.skylead.io/hc/en-us/articles/15710213347729-Campaigns-settings-New",
  },
  WHERE_ARE_MY_MESSAGES_LOCATED: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412754308369-Where-are-my-messages-located/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408594035217-Where-are-my-messages-located/",
  },
  GET_LEAD_BACK_TO_CAMPAIGN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412635551889-How-to-Get-the-Lead-Back-to-the-Campaign/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408433298321-How-to-Get-the-Lead-Back-to-the-Campaign/",
  },
  SKYLEAD_CHAT_LABELS: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412533387025-Chat-labels/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4409245532305-Skylead-chat-labels/",
  },
  SET_UP_WEBHOOK_INTEGRATION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412628710545-How-to-Set-Up-Webhook-Integration/",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408475568401-How-to-Set-Up-Webhook-Integration/",
  },
  GLOBAL_LIMITS_FOR_CAMPAIGNS_SETTINGS_OPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412552618257-Global-Limits/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408021703697-Global-Limits/",
  },
  WORKING_HOURS_SETTINGS_OPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412629584657--Working-hours-Settings-option/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408021735441-Working-hours-option/",
  },
  ACCOUNT_HEALTH_SETTINGS_OPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550439313-Account-Health/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408021576849-Account-Health/",
  },
  BLACKLIST_SETTINGS_OPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/17466255376657-Global-Blacklist-and-Your-Blacklist-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/15714906814993-Global-Blacklist-and-Your-Blacklist-",
  },
  GLOBAL_BLACKLIST: {
    multilead: undefined,
    skylead: "https://help.skylead.io/hc/en-us/articles/15714906814993-Global-Blacklist",
  },
  SYNCHRONIZE_SETTINGS_OPTION: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412550697233-Synchronize-option/",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408029374609-Synchronize-option/",
  },
  INTEGRATE_EMAIL_PROVIDER: {
    multilead: undefined,
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408586206737-How-to-connect-Gmail-through-the-SMTP/",
  },
  CONNECT_EMAIL_WITH_OUTLOOK: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412621293201-How-to-connect-Outlook-through-the-SMTP-",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4409044465681-How-to-connect-Outlook-through-the-SMTP-",
  },
  CONNECT_EMAIL_WITH_GMAIL: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412754191249-How-to-connect-Gmail-through-the-SMTP-",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408586206737-How-to-connect-Gmail-through-the-SMTP-",
  },
  CONNECT_EMAIL_WITH_YAHOO: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412536438545-How-to-connect-Yahoo-through-the-SMTP-",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4409240769937-How-to-connect-Yahoo-through-the-SMTP-",
  },

  EMAIL_DISCOVERY_AND_VERIFICATION_STEP: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4905058167185-Email-Discovery-and-Verification-Step-",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4417878226193",
  },

  CREATE_EMAIL_CAMPAIGN_FROM_CSV: {
    undefined,
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/4408374274321-How-To-Create-a-Campaign-with-Emails-Imported-from-a-CSV-",
  },

  INVITE_TO_CONNECT_VIA_EMAIL: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/6732181789969-Invite-to-connect-Invite-via-Email-",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/6508840138641-Invite-to-connect-Invite-via-Email-",
  },
  ANNOUNCEMENT_RELEASE_2_2_0: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/6732181789969-Invite-to-connect-Invite-via-Email-",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/articles/6508840138641-Invite-to-connect-Invite-via-Email-",
  },
  SMART_SEQUENCE_INMAIL: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412635372177-Inmail-Only-Campaign-",
    skylead: "https://skylead.zendesk.com/hc/en-us/articles/4408977797905-Inmail-Only-Campaign-",
  },
  SET_UP_WEBHOOK_INTEGRATION_BLOG: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/4412628710545-How-to-Set-Up-Webhook-Integration/",
    skylead:
      "https://skylead.io/blog/integrate-crm-with-sales-tool-webhook-explained/#Webhook_Setup_In_Skylead",
  },
  TEAM_MANAGEMENT: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/sections/13116396689937-Team-Management",
    skylead:
      "https://skylead.zendesk.com/hc/en-us/sections/13221483657617-Team-Management-Coming-Soon-",
  },

  HOW_TO_MANAGE_YOUR_TEAM: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13256931024785-How-to-manage-your-team-",
    skylead: "https://help.skylead.io/hc/en-us/articles/13116470846993-How-to-manage-your-team-",
  },

  TEAM_MEMBERS_STATUS: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13258634281617-Team-member-status-",
    skylead: "https://help.skylead.io/hc/en-us/articles/13116476877585-Team-member-status-",
  },

  CUSTOM_ROLES: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13256174217361-Custom-roles-",
    skylead: "https://help.skylead.io/hc/en-us/articles/13116416193681-Custom-roles-",
  },

  WHAT_ARE_TEAM_ROLES_AND_HOW_TO_ASSIGN_THEM: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13256551476497-What-are-team-roles-and-how-to-assign-them-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/13116453671441-What-are-team-roles-and-how-to-assign-them-",
  },

  HOW_TO_INVITE_A_TEAM_MEMBER: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13254420632081-How-to-invite-a-team-member-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/13116375393297-How-to-invite-a-team-member-",
  },

  HOW_TO_DELETE_TEAM_AND_TEAM_MEMBER: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13256628377489-How-to-delete-team-and-team-member-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/13116455365521-How-to-delete-team-and-team-member-",
  },

  HOW_TO_CREATE_A_TEAM: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/13258060538129-How-to-create-a-team-",
    skylead: "https://help.skylead.io/hc/en-us/articles/13116460005265-How-to-create-a-team-",
  },

  HOW_TO_REGISTER_AND_CONNECT_LINKEDIN_ACCOUNT: {
    multilead: undefined,
    skylead:
      "https://help.skylead.io/hc/en-us/articles/13116458830609-How-to-register-and-connect-LinkedIn-account-",
  },

  CUSTOM_TRACKING_DOMAIN: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/18751083932049-How-to-set-up-a-custom-tracking-domain-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/18557147057169-How-to-set-up-a-custom-tracking-domain-",
  },

  CHANGE_TEAM_ZONE: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/18802036854545-Change-the-Time-Zone-of-Your-Account-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/18745272498961-Change-the-Time-Zone-of-Your-Account-",
  },

  EMAIL_SCHEDULER: {
    multilead:
      "https://salesengagementtool.zendesk.com/hc/en-us/articles/18801824211857-How-to-Schedule-Your-Emails-",
    skylead:
      "https://help.skylead.io/hc/en-us/articles/18747274808593-How-to-Schedule-Your-Emails-",
  },
}
